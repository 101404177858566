@import url(https://fonts.googleapis.com/css2?family=Gideon+Roman&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap);
:root {
  
  /* Main Settings  */
  --main-background-color: #232323;

  --text-primary-color: rgb(0, 0, 0);
  --text-secondary-color: rgb(0, 0, 0);

  /* Nav Settings  */
  --nav-link-color: rgb(255, 255, 255);
  --nav-link-hover-color: rgb(203 126 29);
  --nav-link-hover-background-color: none;
  --nav-background-color: none;
  --nav-height: 100px;
  --nav-logo-width: 100px;
  --nav-logo-font-size: 22px;

  /* Mobile Nav Settings  */

  --mobile-nav-background-color: #232323;
  --mobile-nav-logo-height:100px;
  --mobile-nav-link-color: rgb(255, 255, 255);
  --mobile-nav-link-hover-color: rgb(203 126 29);
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: rgb(216, 216, 216);
  --card-border-radius: 10px;
  --card-shadow: 10px 10px 20px -13px rgb(121, 121, 121);

  /* Minting Area */
  --minting-box-color: #212029;
  --minting-box-heading-color: white;
  --minting-box-info-box-color: rgb(65, 65, 65);
  --minting-box-text-color: white;


  /* Minting Button */
  --minting-button-color: linear-gradient(180deg,#604ae5 0%,#813eee 100%);
  --minting-button-text-color: white;

}







.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
}
body {
  color: var(--main-text-color);
  position: relative;
  background: var(--main-background-color);
  margin: 0;
  font-family:  'Gideon Roman', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  background: var(--nav-background-color);
  width: 100%;
  height: var(--nav-height);
  transition: height 0.2s ease-in-out;
}

.nav-container {
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 100%;
  margin: auto;
}
.nav-container a{
  cursor: pointer;
}
.nav-logo {
  width: var(--nav-logo-width);
}

nav a {
  font-size: var(--nav-logo-font-size);
  color: var(--nav-link-color);
  text-decoration: none;
  font-weight: 200;
  padding: 20px;
}

nav a:hover {
  color: var(--nav-link-hover-color);
  background-color: var(--nav-link-hover-background-color);
}

.mobile-menu-button {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 35px;
  right: 74px;
  transition: right 0.2s ease-in-out;
  z-index: 45;
  cursor: pointer;
}

.open-menu {
  left: 0px !important;
}
@media(min-width: 800px){
  .mobile-menu-button{
    display: none!important;
  }
}
.mobile-nav {
  position: fixed;
  min-height: 100vh;
  background: rgb(117, 69, 69);
  background: var(--mobile-nav-background-color, rgb(117, 69, 69));
  left: -100%;
  z-index: 50;
  width: 100%;
  transition: left 0.3s ease;
}

.mobile-nav-close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 35px;
  right: 74px;
  cursor: pointer;
}

.mobile-nav-close-button img {
  height: 100%;
  width: 100%;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 40px;
  list-style: none;
}

.mobile-nav li {
  list-style: none;
  padding: 20px;
}

.mobile-nav a {
  text-decoration: none;
  color: var(--mobile-nav-link-color);
  font-size: var(--mobile-nav-link-size);
}

.mobile-nav a:hover {
  color: var(--mobile-nav-link-hover-color);
}

.mobile-nav-logo {
  height: var(--nav-logo-height);
}

.text-muted {
  color: darkgrey;
}

.nav-social {
  width: 30px;

  padding: 0 10px 0 10px;
}
@media(max-width: 600px){
  .social-icons{
    right: 40%!important;
  }
}
.social-icons {
  display: flex;
  right: 3%;
  position: absolute;
}
@media(max-width: 800px){
.hide-800{
  display: none;
}}
@media only screen and (max-width: 200px) {
  .hide-200 {
    display: none;
  }

  .mobile-menu-button {
    right: 37px;
  }

  .nav-logo {
   width: 50px;
    -webkit-transform: scale(3);
            transform: scale(3);
  }
  nav {
    height: 100px;
  }
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

header {
  max-width: 900px;
  margin: auto;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 40px;
  display: grid;
  grid-gap: 40px;
  gap: 40px;
  padding: 35px;
  flex-direction: column;
}

.mintHeader {
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

@media only screen and (min-width: 200px) {
  header {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    grid-gap: 20px;
    gap: 20px;
  }
}

.text-primary-color {
  color: var(--text-primary-color);
}

.text-secondary-color {
  color: var(--text-secondary-color);
}

.team {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  padding: 20px;
  margin: 40px auto;
  max-width: 900px;
}

.team-item {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px -13px white;
}

.faq {
  max-width: 900px;
  margin: auto;
  align-items: center;
  padding: 20px;
}

.faq div {
  margin-bottom: 20px;
}

.faq p {
  padding: 20px;
}

.rainbow-gallery {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.rainbow-gallery img {
  width: 100%;
}

.card {
  align-items: center;
  border-radius: var(--card-border-radius);
  padding: 40px;
  background: var(--card-background-color);
  box-shadow: var(--card-shadow);
}

.container {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}
@media (max-width: 600px)
{
  .MuiPaper-root > img{
    width: 90px!important;
    margin-left: -5px;
   
  }
  .makeStyles-paper-2{
    padding: 0px;
  }
  .MuiPaper-root p{
    font-size: 12px;
    text-align: center;
  }
  h1{
    font-size: 30px!important;
    font-family:  'Gideon Roman', cursive;
  }

  .topbanner >img{
    width: 100%!important;
  }
}
.content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 90%;
  margin: auto;
}

.mints-available {
  background: rgb(65, 65, 65);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-box {
  background-color: var(--minting-box-color) !important;
  color: var(--minting-box-text-color) !important;
}

.minting-box h5 {
  color: var(--minting-box-heading-color);
}

.minting-info {
  background-color: var(--minting-box-info-box-color);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-info h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.minting-button {
  background: var(--minting-button-color) !important;
}

.custom-mint-container {
  margin-bottom: 20px;
 }



.loading:after {
  content: " .";
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

.introduction{
  display: flex;
  margin-bottom: 50px;
}
.introduction h1{
  font-size: 100px;
  font-weight: 300;
  color: #d37e14;
  text-align: left;
  font-family:  'Gideon Roman', cursive;;
}
.introduction p{
  font-size: 19px;
  color: white;
  text-align: left;
  max-width: 60%;
  padding: 5px;
}
.introduction-hr{
  width: 10%;
 
}

.introduction-text
{
 margin-top: 12.5%;
 max-width: 1000px;
}
@media (max-width: 1700px){
  .introduction-text
{
 max-width: 800px;
}
}
.introduction-text hr{
  background-color: #d37e14;
  height: 3px;
  border: 0px;
}
.MuiPaper-root > img{
  width: 200px;
  border-radius: 10px;
}
.MuiPaper-elevation1{
  box-shadow: none!important;
}
.whitepaper-button{
  text-align: left;
  margin-top: 60px;
  margin-bottom: 60px;
  
}
.whitepaper:hover{
 
  color: #d37e14;
  cursor: pointer;
  
}
ul li{
  list-style-type: "👁️";
}
.whitepaper{
  width: 100px;
  font-weight: 100;
  color: white;
  background-color: #323232;
  padding: 20px;
  text-decoration: none;
  cursor: pointer;
  font-size: 19px;
  box-shadow: 3px 3px 3px black;
}
.ecosystem h1{
  font-size: 100px;
  font-weight: 300;
  color: #d37e14;
}
.ecosystem-container{
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #d37e14;
  border-radius: 10px;
}
.overlay2{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #d37e14;
  border-radius: 10px;
}
.container-hover:hover .overlay {
  opacity: 1;
}
.container-hover:hover .overlay2 {
  opacity: 1;
}
.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.text2{
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: justify;
}
.container-hover > img{
  height: 200px;
  width: 230px;
  border-radius: 10px;
}

.soon h1{
  text-align: center!important;
}
.ecosystem h1{
  margin-bottom: 90px;
}
.soon{
  display: block!important;
  margin-left: auto;
  margin-right: auto;
}
.container-hover {
  position: relative;
  border-radius: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.roadmap{
  margin-top: 50px;
}
.roadmap h1{
  font-size: 100px;
  font-weight: 300;
  color: #d37e14;
}
.vertical-timeline-element-icon{
  width: 30px!important;
  height: 30px!important;
  margin-left: -15px!important; 
  margin-top: 20px;
}
.vertical-timeline-element-content{
  box-shadow: none!important;
}
@media (max-width: 600px)
{
  .vertical-timeline-element-icon{
    margin-left: 5px!important;
  }
  .vertical-timeline ul{
    margin-left: 5px;
  }
}
.vertical-timeline-element:nth-of-type(1) .vertical-timeline-element-date{
  text-align: left!important;
}
.vertical-timeline-element:nth-of-type(3) .vertical-timeline-element-date{
  text-align: left!important;
}
.vertical-timeline-element:nth-of-type(5) .vertical-timeline-element-date{
  text-align: left!important;
}
.lore{
  margin-top: 50px;
}
.lore h1{
  font-size: 100px;
  font-weight: 300;
  color: #d37e14;
}
.lore h2{
  font-size: 20px;
  font-weight: 100;
  color: white;
}
.lore-chapters{
  margin-top: 30px;
  display: block;
  justify-content:left;
}
.lore-chapters h3{
  color: #d37e14;
  font-size: 30px;
  text-align: left;
}
.lore-chapters p{
  color: white;
  font-size: 18px;

  text-align: justify;
}
.chapter{
  width: 90%;
  margin: 20px;
  margin-bottom: 100px;
}
.lore hr{
  width: 100%;
  background-color: #d37e14;
  height: 2px;
  border: 0px;
}
footer{
  height: 40px;
}
footer p{
  color: white;
  position: relative;
  margin-top: 50px;
  text-align: center;
}
.lookingfor-container{
  display: flex;
  justify-content: space-around;
}
.lookingfor h1{
  font-size: 40px;
  font-weight: 300;
  color: #d37e14;
  margin-bottom: 40px;
  text-align: left;
}
.minicard{
  color: white;
  background-color: #d37e14;
  font-size: 30px;
  width: 400px;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  height: 100px;
  margin-bottom: 30px;
}
.lookingfor{
  margin-top: 60px;
  
}
.whywork h1{
  font-size: 40px;
  font-weight: 300;
  color: #d37e14;
  margin-bottom: 40px;
  text-align: left;
}
.whywork ul{
  margin-left: 30px;
}
.hide {
   width: 300px;
   height: 0px;
   color:#232323;

}

.hoverdisplay:hover + .hide {
  width: 500px;
  transition-duration: 1s;
  color: white;
  text-align: left;
  margin-left: 30px;
}

.greek{
  margin-top: 30px;
}
.greek h1{
  font-size: 40px;
  font-weight: 300;
  color: #d37e14;

}
.greek p{
  color: white;
  text-align: justify;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
}
.greek hr{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background-color:#d37e14;
  height: 1px;
  border: none;
  margin-bottom: 40px;
}
form{
  display: block!important;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  border: 1px solid #d37e14;
  padding: 10px;
  border-radius: 10px;
}
label{
  color: white;
  font-size: 24px;
  
}
.block{
  display: block!important;
}
form h1{
  color:#d37e14;
  text-align: center;
  font-size: 46px;
}
input{
  border-radius: 10px;
  box-shadow: 0;
  border: 0;
  height: 25px;
  margin-bottom: 5px;
  width: 500px;

}
textarea{
  border-radius: 10px;
  box-shadow: 0;
  border: 0;
  height: 80px;
  width: 500px;
}
.sendbutton{
  background-color:#d37e14;
  color: white;
  border: 0;
  box-shadow: 0;
  margin-bottom: 0px;
  height: 40px;
  padding: 7px;
  border-radius: 10px;
  font-size: 24px;
  cursor: pointer;
  width: 100px;
}
.__2{
  height: 150px;
  width: 600px;
}
.__2 .text2{
    font-size: 18px;
}
@media (max-width: 1600px) and (min-width: 1400px)
{
  .MuiPaper-root > img{
    width: 150px;
  }
 
}
@media (max-width: 1670px) and (min-width: 1470px){
  .minicard{
    width: 300px;
  }
  
}
@media (max-width: 1400px) and (min-width: 600px)
{
  .MuiPaper-root > img{
    width: 100px;
  }
  .text2{
      font-size: 14px!important;
  }
  .__2{
    height: 150px;
    font-size: 14px!important;
  }
 ._2{
   margin-top: 40px;
   font-size: 14px!important;
 }
 ._1{
  margin-top: 18px;
  font-size: 14px!important;
 }
 ._3{
   margin-top: 23px;
   font-size: 14px!important;
 }
}
@media (max-width: 1470px) and (min-width: 600px){
  .minicard{
    width: 200px;
    font-size: 20px;
  }
}
@media(max-width: 600px)
{
  form{
    width: 300px;
  }
  input{
    width: 300px;
  }
  textarea{
   width: 300px;
  }
  
  .mmt{
    margin-top: 25px;
  }
  .lookingfor-container{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .minicard{
    margin-bottom: 30px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .introduction{
    display: block;
  }
  .introduction h1, .introduction p{
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .introduction hr {
    width: 100%;
  }
  .lookingfor h1{
    text-align: center;
  }
  .greek p{
    width: 90%;
  }
  .whitepaper-button{
    text-align: center;
  }
  .container-hover > img{
    width: 100px;
    height: 100px;
    justify-content: space-between;
  }
  .ecosystem-container{
    max-width: 100%;
  }
  .text{
    font-size: 12px;
  }
  .overlay{
    width: 100px;
  }
}

